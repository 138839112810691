<script setup>
import { router } from '@inertiajs/vue3'
import SocialMediaLoginButton from '../Buttons/SocialMediaLoginButton.vue'
import MicrosoftIcon from '../../../../svg/microsoft-icon.svg'

const azurAuth = () => {
  router.get(route('azur.auth.redirect'))
}

const props = defineProps({
  text: String,
})
</script>

<template>
  <div class="text-sm mt-8">{{ props.text }}</div>
  <div class="mt-5">
    <SocialMediaLoginButton
      class="rounded-xl"
      @click="azurAuth"
    >
      <MicrosoftIcon class="inline mr-2" />
      Microsoft Account
    </SocialMediaLoginButton>
  </div>
</template>
